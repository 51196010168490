import React, { FC } from 'react';
import './Burger.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

type Prop = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Burger: FC<Prop> = ({ open, setOpen }) => {
  const icon = open ? faTimesCircle : faBars;
  return (
    <div className="burger">
      <button
        type="button"
        className="burger__button"
        aria-controls="global-nav"
        aria-expanded={open}
        aria-label="navigation open close"
        onClick={() => setOpen(!open)}
      >
        <FontAwesomeIcon icon={icon} size="lg" />
      </button>
    </div>
  );
};
