import React, { FC } from 'react';

import { Header } from '../Header';
import { Footer } from '../Footer';

import './Layout.scss';

import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;

export const Layout: FC = ({ children }) => (
  <div className="body">
    <Header />

    {children}

    <Footer />
  </div>
);
