import React, { useCallback, useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Nav } from '../Nav/Nav';
import { Burger } from '../Burger';

import './Header.scss';

export const Header = () => {
  const data = useStaticQuery<GatsbyTypes.HeaderQuery>(query);

  const [onTop, setOnTop] = useState(true);
  const [open, setOpen] = useState(false);

  const onScroll = useCallback(() => {
    const offset =
      window.pageYOffset ||
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;

    if (offset >= 20 && !onTop) return false;
    if (offset >= 20 && onTop) {
      setOnTop(false);
      return false;
    }
    setOnTop(true);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <header className={onTop ? 'header' : 'header header--state-scrolled'}>
      <div className="header__logo-a">
        <Link to={`/`} className="header__logo-a">
          {data?.logo?.childImageSharp?.fixed ? (
            <Img
              fixed={data.logo.childImageSharp.fixed}
              alt="shimu.dev"
              style={{ width: '200px', height: '40px' }}
            />
          ) : (
            ''
          )}
        </Link>
      </div>
      <div>
        <Burger open={open} setOpen={setOpen}></Burger>
        <Nav open={open}></Nav>
      </div>
    </header>
  );
};

const query = graphql`
  query Header {
    logo: file(relativePath: { eq: "logo_transparent.png" }) {
      childImageSharp {
        fixed(width: 200, height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
