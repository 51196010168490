import React, { FC } from 'react';
import { Link } from 'gatsby';

import './Nav.scss';

type Prop = {
  open: boolean;
};

export const Nav: FC<Prop> = ({ open }) => {
  return (
    <nav className="nav" aria-hidden={!open}>
      <div className="nav__container">
        <ul className="nav__ul">
          <li className="nav__li">
            <Link to={`/`} className="nav__link">
              TOP
            </Link>
          </li>
          <li className="nav__li">
            <Link to={`/about/`} className="nav__link">
              ABOUT ME
            </Link>
          </li>
          <li className="nav__li">
            <Link to={`/work/`} className="nav__link">
              WORK
            </Link>
          </li>
          <li className="nav__li">
            <Link to={`/blog/`} className="nav__link">
              BLOG
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};
